/* =========================
   📌 Styles généraux de About
========================= */

.about-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 20px;
}

/* =========================
 📌 Section d'Accueil (Header)
========================= */

.about-header {
  background-color: var(--primary-color);
  color: var(--light-color);
  padding: 40px 30px;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  max-width: 900px;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 auto 30px;
}

.about-header h1 {
  font-size: 2.5rem;
}

.about-header p {
  font-size: 1.2rem;
  margin: 15px 0;
}

/* =========================
 📌 Sections About (Mission, Histoire)
========================= */

.about-mission, .about-histoire {
  background-color: var(--light-color);
  margin-top: 30px;
  padding: 40px;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  max-width: 900px;
}


.about-mission, .about-histoire h2 {
  font-size: 1.8rem;
  font-weight: bold;
}

/* =========================
 📌 Liste des Valeurs/Avantages
========================= */

.about-valeurs {
  padding: 40px;
  margin-bottom: 10px;  
}

.about-valeurs h2 {
  font-size: 1.5rem;
  padding-bottom: 10px;
  font-weight: bold;
}

.about-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.about-item {
  flex: 1 1 300px;
  max-width: 300px;
  background-color: var(--light-color);
  padding: 20px;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
}

.about-item h3 {
  font-size: 1.5rem;
  font-weight: bold;
}

/* =========================
 📌 Section Équipe
========================= */

.about-team {
  padding: 40px 20px;
  text-align: center;
}

.about-team h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #007bff;
}

.team-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.team-member {
  text-align: center;
  max-width: 300px;
  background-color: var(--light-color);
  padding: 20px;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
}

.team-photo {
  width: 100%;
  height: auto;
  border-radius: 50%;
  margin-bottom: 10px;
}

.team-member h3 {
  margin: 10px 0 5px;
  font-size: 1.2rem;
  color: #333;
}

.team-member p {
  margin: 0;
  font-size: 0.9rem;
  color: #555;
}

/* =========================
 📌 Ajustements Responsive pour About
========================= */

@media (width <= 768px) {
  .about-page {
    margin-top: -65px;
    padding: 10px;
  }

  .about-header {
      max-width: 100%;
      padding: 30px 20px;
      margin: 0 auto 30px; /* ✅ Ajustement cohérent avec grand écran */
  }

  .about-header h1 {
      font-size: 2rem;
  }

  .about-header p {
      font-size: 1rem;
      padding: 10px 0;
  }

  .about-mission, .about-histoire, .about-valeurs {
      padding: 30px;
      max-width: 100%;
  }

  .about-content h2 {
      font-size: 1.5rem;
  }

  .about-list {
      flex-direction: column;
      align-items: center;
  }

  .about-item {
    flex: 1 1 300px;  /* 🔹 Largeur initiale de 300px, adaptative */
    max-width: 250px; /* 📏 Limite la largeur */
    background-color: var(--light-color);
    padding: 20px;
    border-radius: 8px;
    box-shadow: var(--box-shadow);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center; /* ✅ Centre horizontalement */
    height: auto; /* 📏 Ajuste à la hauteur du contenu */
    min-height: 120px; /* 🔹 Définit une hauteur minimale */
}

  .team-container {
      flex-direction: column;
      align-items: center;
  }

  .team-member {
      max-width: 100%;
  }
}
