/* =========================
   📌 Styles généraux de Home
========================= */

.home-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    padding: 20px;
  }
  
  /* =========================
     📌 Section d'Accueil
  ========================= */
  
  .home-header {
    background-color: var(--primary-color);
    color: var(--light-color);
    padding: 40px 30px;
    border-radius: 8px;
    box-shadow: var(--box-shadow);
    max-width: 900px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto 30px;
  }
  
  .home-header h1 {
    font-size: 2.5rem;
  }
  
  .home-header p {
    font-size: 1.2rem;
    margin: 15px 0;
  }
  
  .cta-button {
    display: inline-block;
    padding: 8px 20px;
    background-color: var(--secondary-color);
    color: var(--light-color);
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: var(--secondary-dark);
  }
  
  /* =========================
     📌 À propos
  ========================= */
  
  .home-about {
    background-color: var(--light-color);
    margin-top: 30px;
    padding: 40px;
    border-radius: 8px;
    box-shadow: var(--box-shadow);
    max-width: 900px;
  }
  
  .home-about h2 {
    font-size: 1.8rem;
    font-weight: bold;
  }
  
  /* =========================
     📌 Services
  ========================= */
  
  .home-services {
    padding: 40px;
    margin-bottom: 10px;
  }
  
  .home-services h2 {
    font-size: 1.5rem;
    padding-bottom: 10px;
    font-weight: bold;
  }
  
  .services-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .service-item {
    flex: 1 1 300px;
    max-width: 300px;
    background-color: var(--light-color);
    padding: 20px;
    border-radius: 8px;
    box-shadow: var(--box-shadow);
  }
  
  .service-item h3 {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  /* =========================
     📌 Responsive
  ========================= */
  
  @media (width <= 768px) {
    .home-header {
      max-width: 100%;
      padding: 30px 20px;
    }
  
    .home-header h1 {
      font-size: 2rem;
    }
  
    .home-header p {
      font-size: 1rem;
      padding: 10px 0;
    }
  
    .cta-button {
      font-size: 1rem;
      padding: 10px 20px;
    }
  
    .home-about {
      padding: 30px;
      max-width: 100%;
    }
  
    .home-services {
      padding: 30px;
    }
  
    .services-list {
      flex-direction: column;
      align-items: center;
    }
  
    .service-item {
      max-width: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
    }
  }
  