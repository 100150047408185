/* =========================
   📌 Styles du Header
========================= */

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  background-color: var(--primary-back-color);
  box-shadow: var(--box-shadow);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: 0 20px;
}

/* ✅ Conteneur du Header */
.header-container {
  display: flex;
  justify-content: center; /* ✅ Centre tout */
  align-items: center;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}

/* ✅ Conteneur du Menu */
.menu-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
}
