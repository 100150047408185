/* =========================
   📌 Styles du authStyles
========================= */

/* Conteneur principal pour la page d'authentification */
.auth-container {
  min-height: 100vh; /* ✅ Permet au container de prendre toute la hauteur */
  display: flex;
  align-items: center;
  padding: 1rem;
  justify-content: center;
  background: radial-gradient(ellipse at 50% 50%, hsl(210deg 100% 97%), hsl(0deg 0% 100%));
  position: fixed; /* ✅ Force le modal à rester bien centré */
    top: 0; /* ✅ Évite tout décalage */
    left: 0;
    width: 100%;
}

/* Carte d'authentification */
.auth-card {
  position: relative;
  width: 100%;
  max-width: 450px;
  padding: 2.5rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
  text-align: center;
}

/* En-tête aligné : Logo à gauche, X à droite */


.close-header {
  display: flex; 
  justify-content: flex-end;
  margin-top: -20px;
  padding-top: 10px;
}

.logo-header {
  display: flex; 
  justify-content: center;
  padding-top: 10px;
}

/* Espacement entre chaque section */
.auth-content {
  margin-top: 20px; /* Ajuste l'espace entre les sections */
}

/* Image du logo */
.auth-logo {
  height: 40px;
  object-fit: contain;
}

/* Bouton de fermeture (X) */
.auth-close-btn {
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
}

/* Formulaire d'authentification */
.auth-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Espacement des sections */
.auth-section {
  padding-top: 10px;
}

/* Bouton de connexion avec Google */
.auth-google-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-weight: bold;
}

/* =========================
   📌 Ajustements Responsive pour authStyles
========================= */

@media (width <= 768px) {
  .auth-container {
    min-height: 100vh; /* ✅ Permet au container de prendre toute la hauteur */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background: radial-gradient(ellipse at 50% 50%, hsl(210deg 100% 97%), hsl(0deg 0% 100%));
    position: fixed; /* ✅ Force le modal à rester bien centré */
    top: 0; /* ✅ Évite tout décalage */
    left: 0;
    width: 100%;
  }

  /* Carte d'authentification */
  .auth-card {
    width: 90%;
    max-width: 400px;
    padding: 2rem;
    box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
  }

  .close-header  {
    display: flex;
    justify-content: flex-end;
    margin-right: -30px;
    padding-top: 10px;    
  }

  .auth-logo {
    height: 25px; /* ✅ Réduit la taille du logo pour les petits écrans */
}

}