/* =========================
   📌 Styles du Footer
========================= */

.footer {
  background-color: var(--primary-color);
  color: var(--light-color);
  padding: 20px 0;
  text-align: center;
  font-family: Arial, sans-serif;
  flex-shrink: 0; /* Empêche le footer de rétrécir */
  border-radius: 8px; /* Si nécessaire, ajoute un contour arrondi */
}

/* 🛠 Conteneur principal du footer */
.footer-container {
  display: flex;
  flex-wrap: wrap; /* Autorise le passage à la ligne sur petits écrans */
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1200px;
  gap: 20px;
  padding: 10px 20px;
}

/* 📌 Sections internes */
.footer-container > div {
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 250px;
}

/* 📌 Titres */
.about h4, .contact h4, .link h4 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #1abc9c;
  text-transform: uppercase;
}

/* 📌 Contenu des sections */
.about p, .contact ul, .link ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* 📨 Contactez-nous */
.contact ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.contact ul li {
  color: var(--light-color);
  display: flex;
  align-items: center;
  gap: 8px;
}

.contact ul li a {
  text-decoration: none;
  color: var(--light-color);
  transition: color 0.3s ease;
}

.contact ul li a:hover {
  color: #1abc9c;
}

/* 🔗 Liens rapides */
.link ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.link ul li a {
  text-decoration: none;
  color: var(--light-color);
  transition: color 0.3s ease;
  font-size: 1rem;
}

.link ul li a:hover {
  color: #1abc9c;
}

/* 📌 Bas du footer */
.footer-bottom {
  margin-top: 20px;
  font-size: 14px;
  border-top: 1px solid #34495e;
  padding-top: 10px;
  text-align: center;
}

.footer-bottom p {
  margin: 0;
}

/* =========================
 📱 Version MOBILE : Ajustements pour une bonne disposition
========================= */
@media (width <= 768px) {
  .footer-container {
      flex-direction: column; /* Empile les sections verticalement */
      text-align: center;
  }

  .footer-container > div {
      align-items: center;
  }

  .contact ul, .link ul {
      align-items: center;
  }
}

/* =========================
 🚀 Correction du positionnement général
========================= */


