/* =========================
   📌 Styles du Menu
========================= */
.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin-bottom: -40px;
}

/* =========================
   📌 Alignement des éléments
========================= */
.menu-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-left: 180px;
}

.container-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}

.menu-list {
  display: flex;
  gap: 20px;
}

/* =========================
   📌 Style des liens
========================= */
.menu-left a,
.menu-right a {
  text-decoration: none;
  color: var(--primary-text-color);
  font-weight: bold;
  font-size: 16px;
  transition: color 0.3s ease;
}

.menu-left a:hover,
.menu-right a:hover {
  color: var(--secondary-color);
}

/* =========================
   📌 Logo
========================= */
.menu-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.menu-logo img {
  max-height: 35px;
  max-width: 120px;
  object-fit: contain;
}

/* =========================
   📌 Icônes (Recherche, Compte)
========================= */
.menu-search,
.menu-account {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative; /* ✅ Nécessaire pour le positionnement du menu dropdown */
}

/* ✅ Ajuste la taille des icônes */
.menu-search svg,
.menu-account svg {
  width: 24px;
  height: 24px;
}

/* =========================
   📌 Zone de Recherche
========================= */
.search-box {
  position: absolute;
  top: calc(100% + 6px); /* ✅ Place le menu juste sous la ligne grise */
  right: 380px; /* ✅ Ajustement vers la gauche pour s'aligner avec l'icône */
  max-width: 200px;
  width: 200px;
  background-color: var(--primary-back-color);
  border: 1px solid #ddd;
  padding: 5px;
  box-shadow: var(--box-shadow);
  z-index: 1000;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
}

.search-input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* =========================
   📌 Dropdown du Compte (Grand Écran)
========================= */
.account-dropdown {
  position: absolute;
  top: calc(100% + 11px); /* ✅ Place le menu juste sous la ligne grise */
  right: 78px; /* ✅ Ajustement vers la gauche pour s'aligner avec l'icône */
  text-align: right;
  min-width: 150px;
  background-color: var(--primary-back-color);
  border: 1px solid #ddd;
  padding: 10px;
  box-shadow: var(--box-shadow);
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transform: translateX(0); /* ✅ Supprime le centrage */
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.menu-account.active .account-dropdown {
  opacity: 1;
  visibility: visible;
  transform: translateX(50%);
}

/* =========================
   📌 Menu Hamburger (Mobile)
========================= */

/* ✅ Caché en mode grand écran */
.menu-ham-container {
  display: none;
}

/* ✅ Affiché uniquement en mobile */
@media (width <= 768px) {
  .menu-ham-container {
    display: flex !important;
    justify-content: flex-end;
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 1000;
  }

  .menu-ham-dropdown {
    position: absolute;
    top: 40px;
    right: 10px;
    background-color: var(--primary-back-color);
    box-shadow: var(--box-shadow);
    border-radius: 5px;
    padding: 10px;
    width: 220px;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  .menu-ham-container:hover .menu-ham-dropdown {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  .menu-ham-dropdown ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    text-align: right;
    padding-right: 15px;
  }

  .menu-ham-dropdown ul li {
    padding: 8px 12px;
    display: flex;
  }

  .menu-ham-dropdown ul li a,
  .menu-ham-dropdown ul li span.popup-link {
    text-decoration: none;
    color: var(--text-color);
    font-size: 1rem;
    transition: color 0.3s ease;
    cursor: pointer;
  }

  .menu-ham-dropdown ul li a:hover,
  .menu-ham-dropdown ul li span.popup-link:hover {
    color: var(--secondary-color);
  }

  .menu-logo img {
    max-height: 30px;
    max-width: 100px;
    object-fit: contain;
  }

  .menu-center {
    margin-left: 0;
  }
}
