/* =========================
   📌 Styles du Layout
========================= */

body, .layout {
  background-color: white !important;
}

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
}

/* 📌 Conteneur centré */
.content-container {
  width: 100%;
  max-width: 1200px;
  background-color: transparent !important;
  padding: 20px;
  box-sizing: border-box;
  margin: 0 auto;
}

/* 📌 Comportement générique du main */
.main {
  flex-grow: 1;
  padding: 20px;
  padding-top: 0; /* Laisse la page gérer le padding si nécessaire */
}

/* 📌 Cas avec header fixe */
.page-with-header {
  padding-top: 520px;
}

@media (width <= 768px) {
  .page-with-header {
    padding-top: 1260px;
  }
}

.page-no-header {
  padding-top: 0 !important;
}
